<template>
    <div>
        <div class="pet-detail-box">
            <div class="shop_box">
                <div class="artnote" v-if="!isShop">
                    <p>暂无店铺信息，<router-link to="/mySpace/shop">去申请</router-link></p>
                </div>
                <div class="shop" v-else>
                    <img :src="setUrl(shopList.showImg)" alt="">
                    <el-descriptions title="我的店铺" :column="2">
                        <el-descriptions-item label="店铺状态">{{shopType[shopList.shopSt]}}</el-descriptions-item>
                        <el-descriptions-item label="店铺号">{{shopList.shopId}}</el-descriptions-item>
                        <el-descriptions-item label="审核描述">{{shopList.authDesc}}</el-descriptions-item>
                        <el-descriptions-item label="店铺名称">{{shopList.shopName}}</el-descriptions-item>
                        <el-descriptions-item label="联系电话">{{shopList.shopPhone}}</el-descriptions-item>
                        <el-descriptions-item label="详细地址">{{shopList.area}} {{shopList.address}}</el-descriptions-item>
                        <el-descriptions-item label="店铺描述" :span="2">
                            <el-tag size="small">{{shopList.shopDesc}}</el-tag>
                        </el-descriptions-item>
                    </el-descriptions>
                    <el-carousel :interval="3000" type="card" height="200px" v-if="shopList.showBanner">
                        <el-carousel-item v-for="item in showBanner" :key="item">
                            <el-image
                                    :src="setUrl(item)"
                                    fit="scale-down"></el-image>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <el-button type="primary" round v-if="shopList && shopList.shopSt==3" @click="goEdit">>去编辑</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import instance from "@/services/instance";

    export default {
        name: "myShop",
        data(){
            return {
                isShop:false,
                shopId:"",
                shopType:["未开启","审核中","审核通过","审核未通过","已关闭"],
                shopList:[],
                showBanner:[]
            }
        },
        created(){
            this.getUser();
        },
        methods:{
            getUser(){//获取是否有店铺
                instance
                    .post('/officialWebsiteMgr/resource/user/userDesc')
                    .then((res) => {
                        if (res.code == 0) {
                            if(res.data.shop && res.data.shop != null){
                                this.isShop=true;
                                this.shopList=res.data.shop;//店铺信息
                                this.shopId=res.data.shop.shopId;//店铺id
                                if(this.shopList.showBanner && this.shopList.showBanner != null){
                                    this.showBanner  = this.shopList.showBanner.split(",");
                                }
                            }else{
                                this.isShop=false;
                            }
                        }else{
                            this.$message.error(res.msg)
                        }
                    })
            },
            setUrl(url) {
                if (url && url.indexOf("http") == 0) {
                    return url;
                } else {
                    return "/officialWebsiteMgr/resource/file/getImage?fileName=" + url;
                }
            },
            goEdit(){
                this.$router.push({
                    name: 'editShop',
                    params: {
                        id: this.shopId,
                    },
                })
            }
        }
    }
</script>

<style>
.shop_box{
    padding: 50px 20px 20px 30px;
    min-height:410px;
    text-align: center;
    box-sizing: border-box;
}
.shop_box button{
    margin-top:20px;
}
.shop_box>div{
    margin-bottom: 20px;
}
.el-descriptions-item__container .el-descriptions-item__label:not(.is-bordered-label){
    flex-shrink: 0;
}
.el-tag--small{
    white-space: normal;
    height:auto;
}
.el-tag{
    height:auto;
    white-space: normal;
}
</style>